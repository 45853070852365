import Chart from 'react-apexcharts';
import NoDataAlert from '../../common/returns/NoDataAlert';
import { colorsPallete } from '../../../constants';
import TitleCard from '../TitleCard';
import TitleCardSimple from '../../common/TitleCardSimple';

const ChartBarDefault = ({ chartDataProps, typeChart, nameSerie, unidade }) => {
	let data = [];
	let color = '';
	let gradient = '';
	
	if (!chartDataProps) {
		return <NoDataAlert />;
	}
	const nomes = chartDataProps.reduce(
		(valorInicial, regime) => valorInicial.concat(regime.nome),
		[]
	);

	if(chartDataProps.length === 0) {
		return null;
	}

	
	if (typeChart === 'potenciaskW') {
		data = chartDataProps
		? chartDataProps.reduce(
			(valorInicial, regime) =>
				valorInicial.concat(regime.potencia),
			[]
		)
		: [];
		color = '#0CABF5';
		gradient = '#6078ea';
	} else if (typeChart === 'consumokWh') {
		const potencias = chartDataProps.reduce(
			(valorInicial, regime) =>
				valorInicial.concat(regime.potencia),
			[]
		);
		const consumo = potencias.map((potencia) => {
			return (
				potencia *
				unidade.horasTrabalhadasDia | 0 *
				unidade.diasDeTrabalhoMes | 0
			);
		});
		
		data = consumo;
		color = '#02FFA9';
		gradient = '#02FFA980';
	}
	
	const series = [
		{
			name: nameSerie ? nameSerie : '',
			data: data,
			color: color,
		},
	];

	const options = {
		chart: {
			stacked: false,
			toolbar: {
				show: false,
			},
		},
		grid: {
			show: false,
			borderColor: '#40475D',
			strokeDashArray: 0,
		},
		plotOptions: {
			bar: {
				borderRadius: 3,
				borderRadiusApplication: 'end',
				borderRadiusWhenStacked: 'last',
				columnWidth: '85%',
				horizontal: false,
				dataLabels: {
					position: 'top',
				},
			},
		},
		dataLabels: {
			enabled: true,
			textAnchor: 'middle', //
			style: {
				colors: ['#FFF'],
				fontSize: '14px',
			},
			background: {
				enabled: false,
				dropShadow: {
					enabled: true,
					top: 3,
					left: 3,
					blur: 3,
				},
			},
			offsetY: -30,
			offsetX: 0,
			dropShadow: {
				enabled: true,
				left: 2,
				top: 2,
				opacity: 0.5,
			},
			formatter: function (value) {
				var formattedValue = new Intl.NumberFormat(
					'pt-BR'
				).format(value.toFixed(2));
				return formattedValue;
			},
		},
		stroke: {
			width: 1,
			colors: ['#33333380'],
		},
		xaxis: {
			categories: chartDataProps ? nomes : {},
			position: 'bottom',
			axisBorder: {
				show: false,
			},
			axisTicks: {
				show: false,
				color: '#40475D',
			},
			labels: {
				show: true,
				style: {
					colors: colorsPallete.map((color) => color.color),
					fontSize: '18px',
				},
				offsetY: 10,
			},
		},
		yaxis: {
			reversed: false,
			opposite: false,
			floating: false,
			axisBorder: {
				show: false,
			},
			axisTicks: {
				show: false,
				color: '#40475D',
			},
			labels: {
				show: false,
				style: {
					colors: '#40475D',
					fontSize: '16px',
					fontStyle: 'bold',
				},
				formatter: function (value) {
					var formattedValue = new Intl.NumberFormat(
						'pt-BR'
					).format(value.toFixed(2));
					return formattedValue;
				},
			},
		},
		tooltip: {
			theme: 'dark',
			y: {
				show: true,
				formatter: function (value) {
					var formattedValue = new Intl.NumberFormat(
						'pt-BR'
					).format(value.toFixed(2));
					return formattedValue;
				},
			},
		},

		fill: {
			type: 'gradient',
			gradient: {
				type: 'radial',
				shadeIntensity: 1,
				gradientToColors: [gradient],
				inverseColors: false,
				opacityFrom: 1,
				opacityTo: 1,
				stops: [],
				colorStops: [],
			},
		},
		legend: {
			show: false,
			showForSingleSeries: true,
			showForNullSeries: true,
			showForZeroSeries: true,
			position: 'top',
			horizontalAlign: 'center',
			fontStyle: 'bold',
			fontColor: 'white',
			fontSize: '22px',
			labels: {
				colors: 'white',
				useSeriesColors: false,
			},
			onItemClick: {
				toggleDataSeries: true,
			},
			onItemHover: {
				highlightDataSeries: true,
			},
		},
	};

	return (
		<div className="bg-transparent w-full h-full px-2 py-4">
			<TitleCardSimple className={"text-center"}>{nameSerie}</TitleCardSimple>
			<TitleCard>Total: 
				<span className="text-accent">
					{' '}{data.reduce((a,b) => a + b,0).toLocaleString('pt-BR', {
						maximumFractionDigits: 2,
					})}
				</span></TitleCard>
			<Chart
				options={options}
				series={series}
				type="bar"
				width="100%"
				height={320}
			/>
		</div>
	);
};

export default ChartBarDefault;
