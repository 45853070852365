import { useCallback, useEffect, useState } from 'react';
import ConsumoEnergiaMensal from '../../../components/monitoramento/energia/ConsumoEnergiaMensal';
import ConsumoEnergiaDiario from '../../../components/monitoramento/energia/ConsumoEnergiaDiario';
import DemandaAtivaDiario from '../../../components/monitoramento/energia/DemandaAtivaDiario';
import SaladeMaquinasDiario from '../../../components/monitoramento/energia/SaladeMaquinasDiario';
import DemandaReativaDiario from '../../../components/monitoramento/energia/DemandaReativaDiario';
import apiLeituraEnergia from '../../../services/apis/Leituras/apiLeituraEnergia';
import { useNavigate } from 'react-router-dom';
import getCurrentUnidadeId from '../../../funcs/getCurrentUnidadeId';
import { getClientTimeZoneOffset, getUnidadeIdCookie } from '../../../funcs';
import DatePickerGetData from '../../../components/common/DatePickerGetData';
import apiRelatorio from '../../../services/apis/apiRelatorio';
import apiUnidade from '../../../services/apis/apiUnidade';
import useToast from '../../../hooks/useToast';

function MonitoramentoEnergia() {
	
	const [leituraEnergia, setLeituraEnergia] = useState([]);
	
	const { erro } = useToast();
	
	const [consumoEnergiaMensal, setConsumoEnergiaMensal] = useState([]);
	const [consumoEnergiaMensalDetalhado, setConsumoEnergiaMensalDetalhado] =
	useState();
	
	const [isLoadingRelatorio, setIsLoadingRelatorio] = useState(false);
	
	const [consumoEnergiaDiario, setConsumoEnergiaDiario] = useState([]);
	const [consumoEnergiaDiarioDetalhado, setConsumoEnergiaDiarioDetalhado] =
	useState();
	
	const unId = getCurrentUnidadeId();
	const [unidade, setUnidade] = useState();

	const [
		consumoEnergiaDemandaAtivaDetalhado,
		setConsumoEnergiaDemandaAtivaDetalhado,
	] = useState();
	
	const [
		consumoEnergiaDemandaReativaDetalhado,
		setConsumoEnergiaDemandaReativaDetalhado,
	] = useState();
	
	useEffect(() => {
		apiUnidade.getById(unId).then((res) => {
			setUnidade(res.data);
		}).catch((error) => {
			console.error('Erro ao buscar os dados:', error);
		})
	},[unId]);

	const [energia, setEnergia] =
	useState([]);

	const navigate = useNavigate();

	const onSearch = useCallback((selectedDate) => {
		const formattedDate = selectedDate
			? `${selectedDate.getFullYear()}-${(
					selectedDate.getMonth() + 1
			  )
					.toString()
					.padStart(2, '0')}-${selectedDate
					.getDate()
					.toString()
					.padStart(2, '0')}`
			: '';
		const dataObject = {
			timeZoneNumber: getClientTimeZoneOffset(),
			data: formattedDate,
		}

		try {
			if (unId === undefined) {
				navigate('/escolha-unidade');
				throw new Error('Unidade não selecionada');
			}
			//GET LEITURAS
			//MENSAL
			apiLeituraEnergia
				.getLeituraMensal(unId, dataObject)
				.then((res) => {
					setConsumoEnergiaMensal(res.data);
				});
			//MENSAL DETALHADO
			apiLeituraEnergia
				.getLeituraMensalDetalhado(unId, dataObject)
				.then((res) => {
					setConsumoEnergiaMensalDetalhado(res.data[0]);
				});
			//DIARIO
			apiLeituraEnergia
				.getLeituraDiario(unId, dataObject)
				.then((res) => {
					setConsumoEnergiaDiario(res.data);
				});
			//DIARIO DETALHADO
			apiLeituraEnergia
				.getLeituraDiarioDetalhado(unId, dataObject)
				.then((res) => {
					setConsumoEnergiaDiarioDetalhado(res.data[0]);
				});
			apiLeituraEnergia
				.getLeituraDemandaAtivaDetalhado(unId, dataObject)
				.then((res) => {
					setConsumoEnergiaDemandaAtivaDetalhado(
						res.data[0]
					);
				});

			apiLeituraEnergia
				.getLeituraDemandaReativaDetalhado(unId, dataObject)
				.then((res) => {
					setConsumoEnergiaDemandaReativaDetalhado(
						res.data[0]
					);
				});
			apiLeituraEnergia.getLeiturasEnergiaByDate(unId, dataObject)
			.then((res) => {
				setLeituraEnergia(res.data);
			});
			//SALA DE MÁQUINAS
			apiLeituraEnergia
				.getLeituraSalaMaquinas(unId, dataObject)
				.then((res) => {
					setEnergia(res.data);
				});

		} catch (error) {
			console.error(error.message);
		}
	},[unId, navigate]);

	useEffect(() => {
		if (getUnidadeIdCookie() === undefined) {
			navigate('/escolha-unidade');
		}
	}, [navigate]);

	const gerarRelatorio = async (tempSelectedDateDe, tempSelectedDateAte) => {
		try {
            setIsLoadingRelatorio(true);
            const timeZoneOffset = getClientTimeZoneOffset();

            const dataInfo = {
                dataInicio: tempSelectedDateDe,
                dataFim: tempSelectedDateAte,
                TimeZoneNumber: timeZoneOffset
            }

            const dataInicioFormatada = dataInfo.dataInicio.toLocaleString('pt-BR', {
				day: '2-digit',
				month: '2-digit',
				year: 'numeric',
				hour: '2-digit',
				minute: '2-digit',
				hour12: false 
			}).replaceAll(",", "_").replaceAll(" ", "");

            const dataFinalFormatada = dataInfo.dataFim.toLocaleString('pt-BR', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                hour12: false
            }).replaceAll(",", "_").replaceAll(" ", "");

            //se data inicio for maior que data final
            if (dataInfo.dataInicio > dataInfo.dataFim) {
                erro('Data de início precisa ser maior que data final');
                return;
            }

            const response = await apiRelatorio.relatorioLeituraEnergia(unidade.id, dataInfo);
            const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }));
            const link = document.createElement('a');
            link.href = url;

            link.setAttribute('download', `Leituras_Energia_${unidade.nome}_${dataInicioFormatada}-${dataFinalFormatada}.xlsx`);

            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

        } catch (error) {
            erro(error.message);
        } finally {
            setIsLoadingRelatorio(false);
        }
	}

	return (
		<>
			<div className="pb-8">
				<DatePickerGetData onSearch={onSearch} gerarRelatorio={gerarRelatorio} loading={isLoadingRelatorio} />
				<ConsumoEnergiaMensal
					dados={consumoEnergiaMensal}
					dadosDetalhado={consumoEnergiaMensalDetalhado}
				/>
				<div className="flex justify-center gap-4 flex-col p-2">
					<div className="flex flex-col lg:flex-row gap-4">
						<ConsumoEnergiaDiario
							dados={consumoEnergiaDiario}
							dadosDetalhado={
								consumoEnergiaDiarioDetalhado
							}
						/>
							<DemandaAtivaDiario
								dados={
									leituraEnergia
								}
								dadosDetalhado={
									consumoEnergiaDemandaAtivaDetalhado
								}
							/>
					</div>
					<div className="flex flex-col lg:flex-row gap-4">
						<SaladeMaquinasDiario
							dados={energia}
						/>
						<DemandaReativaDiario
							dados={leituraEnergia}
							dadosDetalhado={
								consumoEnergiaDemandaReativaDetalhado
							}
						/>
					</div>
				</div>
			</div>
		</>
	);
}

export default MonitoramentoEnergia;
