import { apiLink } from '../../constants/env';
import axiosInstance from '../AxiosInstance';

const apiBombas = {
    fetchApi: async (page) => {
        const URL = `${apiLink}api/bomba?page=` + page;
        return await axiosInstance.get(URL)
            .then((res) => res)
            .catch(error => {
                console.error('Erro ao buscar os dados:', error);
            })
    },
    postApi: async (bomba) => {
        const URL = `${apiLink}api/bomba`;
        return await axiosInstance.post(URL, bomba)
            .then((res) => res)
            .catch(error => {
                console.error('Erro ao fazer o post dos dados:', error);
                throw new Error(error);
            })
    },
    putApi: async (id, bomba) => {
        const URL = `${apiLink}api/bomba/${id}`;
        return await axiosInstance.put(URL, bomba)
            .then((res) => res)
            .catch(error => {
                console.error('Erro ao fazer o post dos dados:', error);
                throw new Error();

            })
    },
    deleteApi: async (id) => {
        const URL = `${apiLink}api/bomba/status/${id}`;
        return await axiosInstance.get(URL)
            .then((res) => res)
            .catch(error => {
                console.error('Erro ao deletar o Bomba:', error);
                throw new Error();

            })
    },
    getByUnidadeId: async (id) => {
        const URL = `${apiLink}api/bomba/unidade/${id}`;
        return await axiosInstance.get(URL)
            .then((res) => res)
            .catch(error => {
                console.error('Erro ao pegar unidade da bomba:', error);
                throw new Error();

            })
    },
    getByUnidadeIdV2: async (id) => {
        const URL = `${apiLink}api/bomba/v2/unidade/${id}`;
        return await axiosInstance.get(URL)
            .then((res) => res)
            .catch(error => {
                console.error('Erro ao pegar unidade da bomba:', error);
                throw new Error();

            })
    },
    getEquipamentoComUltimasLeituras: async (equipamentoId, numLeituras) => {
        const URL = `${apiLink}api/bomba/${equipamentoId}/numero-leituras/${numLeituras}`;
        return await axiosInstance.get(URL)
            .then((res) => res)
            .catch(error => {
                console.error('Erro ao pegar bomba:', error);
                throw new Error('Erro ao pegar bomba:', error);
            })
    }

}

export default apiBombas; 