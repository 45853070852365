import { useEffect, useState } from "react";
import Table from "../common/table";
import getCurrentUnidadeId from "../../funcs/getCurrentUnidadeId";
import { format } from "date-fns";
import { orderBy } from "../../funcs";
import apiRegimes from "../../services/apis/apiRegimes";
import apiUltimaLeituraCondensacao from "../../services/apis/Leituras/apiUltimaLeituraCondensacao";

export default function Regimes() {

    const [leituras, setLeituras] = useState([]);
    const unidadeId = getCurrentUnidadeId();

    useEffect(() => {
        const fetchData = async () => {
        const res = await apiRegimes.getByUnidadeId(unidadeId);
            const regimes = res.data;
            
            const leiturasRegimes = await Promise.all(regimes.map(async (regime) => { 
                if(regime.tipoRegime === "Condensação") {
                    const resLeitura = await apiUltimaLeituraCondensacao.fetchApi(regime.id);

                    return {...resLeitura.data, tipoRegime:regime.tipoRegime};
                } else {
                    return {...regime.leiturasRegimes[0], tipoRegime: regime.tipoRegime}
                }
            }));        
                
            orderBy(leiturasRegimes, "regimeId");

            setLeituras(leiturasRegimes);
        }
        fetchData();

        },[unidadeId]);
        
    return 	<div className="w-full">
        <Table.Root>
            <Table.Head>
                <Table.Header>Regime id</Table.Header>
                <Table.Header>Tipo regime</Table.Header>
                <Table.Header>Data hora</Table.Header>
                <Table.Header>Pressão atual</Table.Header>
            </Table.Head>
            <Table.Body>
                {leituras.filter(leitura => leitura.dataHora !== "0001-01-01T00:00:00" && leitura?.id).map(
                    (leitura) => (
                        <Table.Row
                            key={leitura.id}
                        >
                            <Table.Data>{leitura.regimeId}</Table.Data>
                            <Table.Data>{leitura.tipoRegime}</Table.Data>
                            <Table.Data>{leitura?.dataHora && format(leitura.dataHora, "dd/MM/yyyy HH:mm:ss")}</Table.Data>
                            <Table.Data>{leitura.pressaoAtual}</Table.Data>
                        </Table.Row>
                    )
                )}
            </Table.Body>
        </Table.Root>
    </div>
}