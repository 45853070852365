import axios from "axios";
import { apiLink } from "../constants/env";
import { clearAuthCookies, getAccessToken, getUnidadeIdCookie } from "../funcs";
import apiAtuth from "./apis/apiAuth";

let axiosInstance = axios.create({
    baseURL: apiLink,
    withCredentials: true,
});

axiosInstance.interceptors.request.use(
    (config) => {
        const token = getAccessToken();
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

axiosInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {
        if (error.response && error.response.status === 403) {
            try {
                await apiAtuth.refreshTokenUnidade(getUnidadeIdCookie());
                return axios(error.config);
            } catch (refreshError) {
                clearAuthCookies();
                window.location.href = "/login";
                return Promise.reject(refreshError);
            }
        }

        return Promise.reject(error);
    }
);

export default axiosInstance;
