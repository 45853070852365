import React from 'react';
import { cn } from '../../../lib/utils/utils';

export const TableHeader = ({
	children,
	className,
	span,
	islower = false,
	...props
}) => {
	if (islower) {
		return (
			<th
				scope="col"
				colSpan={span}
				className={cn(
					'font-semibold pb-1 text-sm 3xl:text-sm text-center capitalize text-accent/80 px-2 drop-shadow-1xl lg:truncate lg:max-w-[110px]',
					className
				)}
				title={(typeof children === 'string') ?  children.toString() : '' }

				{...props}
			>
				{children}
			</th>
		);
	}
	return (
		<th
			scope="col"
			colSpan={span}
			className={cn(
				'pt-2 pb-[2px] font-semibold text-sm 3xl:text-lg text-center uppercase text-secondary px-2 drop-shadow-1xl lg:truncate lg:max-w-[110px]',
				className
			)}
			title={(typeof children === 'string') ?  children.toString() : '' }

			{...props}
		>
			{children}
		</th>
	);
};
