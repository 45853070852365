import { apiLink } from '../../constants/env';
import axiosInstance from '../AxiosInstance';

const apiTbu = {
    ultimaLeitura: async (unId) => {
        const URL = `${apiLink}api/leitura-tbu/ultima-leitura?unidadeId=${unId}`;
        return await axiosInstance.get(URL)
            .then((res) => res)
            .catch(error => {
                console.error('Erro ao buscar os dados:', error);
                throw new Error();

            })
    },
    getEquipamentoComUltimasLeituras: async (equipamentoId, numLeituras) => {
        const URL = `${apiLink}api/leitura-tbu/${equipamentoId}/numero-leituras/${numLeituras}`;
        return await axiosInstance.get(URL)
            .then((res) => res)
            .catch(error => {
                console.error('Erro ao pegar tbu:', error);
                throw new Error('Erro ao pegar tbu:', error);
            })
    }
}

export default apiTbu;

