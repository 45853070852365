import { useContext } from "react";
import Ambientes from "../../components/ultimas_leituras/Ambientes";
import Chillers from "../../components/ultimas_leituras/Chillers";
import Equipamentos from "../../components/ultimas_leituras/Equipamentos";
import { AuthContext } from "../../contexts/AuthContext";
import NotFound from "../NotFound";

export default function UltimasLeituras() {


    
    const { userInfo } = useContext(AuthContext);

    if(!userInfo?.cargo) {
        return null;
    }
    
    return (
        ["ADMIN", "SUPER_USER"].includes(userInfo.cargo) ?  
        <div className="w-full flex flex-col gap-5 pb-5">
            <Equipamentos/>
            <Ambientes/>
            <Chillers/>
        </div> : 
        <NotFound/>
    )
}