import { format } from "date-fns";
import useAsyncFunction from "../../hooks/useAsyncFunction"
import BasicTable from "../common/BasicTable";
import Table from "../common/table";
import apiVentilador from "../../services/apis/apiVentilador";

export default function VentiladorTable({ equipamento }) {
        
    const { data, loading, error } = useAsyncFunction(async () => await apiVentilador.getEquipamentoComUltimasLeituras(equipamento.id, 50)) || [];

    if (loading) return <p>Carregando...</p>;

    if(!data || error) {
        return null
    }
        

    const leituras = data?.leiturasVentiladores || [];

    
    return <BasicTable 
        headerContent={["LEITURA ID", "LIGADO", "DATA HORA", "CORRENTE", "FREQUÊNCIA", "POTÊNCIA CALCULADA"]} 
    >   
        {
            leituras.map((leitura) => (
            <Table.Row key={leitura.id}>
                <Table.Data>{leitura.id}</Table.Data>
                <Table.Data>{leitura.ligado ? "on" : "off"}</Table.Data>
                <Table.Data>{format(leitura.dataHora, "dd/MM/yyyy HH:mm:ss")}</Table.Data>
                <Table.Data>{leitura.corrente}</Table.Data>
                <Table.Data>{leitura.frequencia}</Table.Data>
                <Table.Data>{leitura.potenciaCalculada}</Table.Data>
         
            </Table.Row>
            ))
        }
    </BasicTable>

}