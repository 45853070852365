'use client';
import { useContext, useEffect, useRef, useState } from 'react';
import { PiUserCircleDuotone } from 'react-icons/pi';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../contexts/AuthContext';
import apiUnidade from '../../services/apis/apiUnidade';
import apiEmpresa from '../../services/apis/apiEmpresa';
import { getUnidadeIdCookie } from '../../funcs';

const UserConfig = () => {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [autorizado, setAutorizado] = useState(false);
	const [userUnidade, setUserUnidade] = useState();
	const [userEmpresa, setUserEmpresa] = useState();
	const [isUsuarioBasico, setIsUsuarioBasico] = useState(false);
	const [unId, setUnId] = useState(null);

	const { verificarCargo, deslogar, userInfo } = useContext(AuthContext);

	const modalRef = useRef(null);

	const handleToggleModal = () => {
		setIsModalOpen(!isModalOpen);
	};

	useEffect(() => {
		verificarCargo('SUPER_USER', 'ADMIN').then((res) => {
			setAutorizado(res);
		});
		verificarCargo('BASIC_USER', 'ADMIN_UNIDADE').then((res) => {
			setIsUsuarioBasico(res);
		});
	}, [verificarCargo]);

	const handleClickOutside = (event) => {
		if (modalRef.current && !modalRef.current.contains(event.target)) {
			setIsModalOpen(false);
		}
	};

	useEffect(() => {
		const unIdLocalStorage = getUnidadeIdCookie();

		if (unIdLocalStorage) {
			apiUnidade.getById(unIdLocalStorage).then((res) => {
				setUserUnidade(res.data);
			});
		}
		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [unId]);

	useEffect(() => {
		userUnidade !== undefined &&
			apiEmpresa.getById(userUnidade.empresaId).then((res) => {
				setUserEmpresa(res.data);
			});
	}, [userUnidade]);

	window.addEventListener('storage', () => {
		setUnId(getUnidadeIdCookie());
	});

	return (
		<div className="relative" ref={modalRef}>
			<PiUserCircleDuotone
				className="w-9 h-9 rounded-full cursor-pointer fill-white/20 hover:fill-accent/90 transition-all duration-150"
				onClick={handleToggleModal}
			/>
			{isModalOpen && (
				// Dropdown menu
				<div
					id="userDropdown"
					className="z-100 divide-y rounded-md shadow-lg w-60 bg-darkaccent divide-secondary absolute -right-4 2xl:-right-24 top-16 text-xs"
				>
					<div className="p-2 text-lg text-white text-center">
						<div className="font-bold">
							{userInfo.nome} {userInfo.sobrenome}
						</div>
						{/* <div className="">anderson@cess.com</div> */}
					</div>
					<div className="text-lg text-center p-2">
						<div className="px-4 text-white text-center">
							<div className="font-bold text-accent">
								Empresa:
							</div>
							<div className="uppercase">
								{userEmpresa?.nome || '-'}
							</div>
						</div>
						<div className="px-4 text-lg text-white text-center">
							<div className="font-bold text-accent">
								Unidade:
							</div>
							<div className="uppercase">
								{userUnidade?.nome || '-'}
							</div>
						</div>
					</div>

					<ul
						className="py-1 text-lg text-gray-700 text-center"
						aria-labelledby="avatarButton"
					>
						<Link to={'/monitoramento/geral'}>
							<li className="block p-1 hover:bg-primary text-white">
								Monitoramento Geral
							</li>
						</Link>
						{autorizado && (
							<Link to={'/admin/grupo/1'}>
								<li className="block p-1 hover:bg-primary text-white">
									Admin
								</li>
							</Link>
						)}
						{!isUsuarioBasico && (
							<Link to={'/escolha-unidade'}>
								<li className="block p-1 hover:bg-primary text-white">
									Alterar Empresa
								</li>
							</Link>
						)}
					</ul>
					<div
						className="py-1 text-center"
						onClick={() => deslogar()}
					>
						<p
							href="#"
							className="block p-1 text-lg hover:bg-primary text-white cursor-pointer"
						>
							Sign out
						</p>
					</div>
				</div>
			)}
		</div>
	);
};

export default UserConfig;
