import { useState, useRef } from "react";

export default function useAsyncFunction(asyncFn) {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const hasFetched = useRef(false);

  if (!hasFetched.current) {
    hasFetched.current = true;
    asyncFn()
      .then((result) => {
        setData(result?.data);
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return { data, loading, error };
}
