import { format } from "date-fns";
import useAsyncFunction from "../../hooks/useAsyncFunction"
import BasicTable from "../common/BasicTable";
import Table from "../common/table";
import apiChiller from "../../services/apis/apiChiller";

export default function ChillerTable({ equipamento }) {
        
    const { data, loading, error } = useAsyncFunction(async () => await apiChiller.getEquipamentoComUltimasLeituras(equipamento.id, 50)) || [];

    if (loading) return <p>Carregando...</p>;

    if(!data || error) {
        return null
    }
        
    const leituras = data?.leiturasChillers || [];
    
    return <BasicTable 
        headerContent={["LEITURA ID", "LIGADO", "DATA HORA", "TEMPERATURA ENTRADA", "TEMPERATURA SAÍDA"]} 
    >   
        {
            leituras.map((leitura) => (
            <Table.Row key={leitura.id}>
                <Table.Data>
                    {
                        leitura.id
                    }
                </Table.Data>
                <Table.Data>
                    {
                        leitura.ligado ? "on" : "off"
                    }
                </Table.Data>
                <Table.Data>
                    {
                        format(leitura.dataHora, "dd/MM/yyyy HH:mm:ss") === "01/01/0001 00:00:00" ? "Sem leitura" : format(leitura.dataHora, "dd/MM/yyyy HH:mm:ss")
                    }
                </Table.Data>
                <Table.Data>
                    {
                        leitura.temperaturaEntrada
                    }
                </Table.Data>
                <Table.Data>
                    {
                        leitura.temperaturaSaida
                    }
                </Table.Data>
            </Table.Row>
            ))
        }
    </BasicTable>

}