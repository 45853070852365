import { createContext, useCallback, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import apiAuth from "../services/apis/apiAuth";
import { ToastContext } from "./ToastContext";
import apiGrupo from "../services/apis/apiGrupo";
import { publicPages } from "../constants";
import { clearAuthCookies, getAccessToken, getRefreshToken, setAccessToken, setRefreshToken, setUnidadeIdCookie } from "../funcs";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [userInfo, setUserInfo] = useState({});
    const location = useLocation().pathname;

    const { aviso } = useContext(ToastContext);

    // O STATE KEY SERVE PARA AUALIZAR O CONTEXT AO PEGAR OS DADOS DO USUÁRIO

    const navigate = useNavigate();


    const deslogar = useCallback(() => {
        clearAuthCookies();
        setUserInfo({});
        navigate('/login');
    }, [navigate]);


    useEffect(() => {
        const isPublicPage =  publicPages.some(path => location.includes(path));
        //VERIFICA SE O USUÁRIO ESTÁ LOGADO
        if (!isPublicPage && getAccessToken() === undefined) {
            navigate('/login');

            //VERIFICA SE O AuthContext JA TEM OS DADOS DO USUÁRIO, SE NÃO TIVER ELE PEGA
        } else if (!isPublicPage) {
            apiAuth.userInfo().then(res => {
                setUserInfo(res.data);
            }).catch(error => {
                aviso("Sua sessão expirou! Entre novamente.")
                navigate('/login');
                deslogar();
                console.error(error.message);
            })
        }


    }, [location, aviso, deslogar, navigate]);

    useEffect(() => {

        if (Object.keys(userInfo).length !== 0 && location !== '/login') {
            const dataAtual = new Date();
            const dataToken = new Date(userInfo.issuedAt);
            const diff = dataAtual - dataToken;
            //TRANSFORMA OS MILISSEGUNDOS EM HORA
            const diffHoras = (diff / 3600000);
            if (diffHoras >= 0.5) {
                const token = getAccessToken();
                const refreshToken = getRefreshToken();

                const tokens = {
                    token: token,
                    refreshToken: refreshToken,
                }
                apiAuth.refreshToken(tokens).then(res => {
                    setAccessToken(res.data.token);
                    setRefreshToken(res.data.refreshToken);
                }).catch(error => {
                    console.error(error.message)
                });

            }

            //USUÁRIO ATUALIZADO
            if (userInfo.isAtualizado !== false) {
                aviso("Seu usuário foi atualizado. Por favor, entre novamente!")
                deslogar();
                navigate('/login');
            }
        }
    }, [userInfo, aviso, location, navigate, deslogar]);

    const verificarCargo = async (cargo1, cargo2) => {
        const cargoUsuario = userInfo.cargo;
        if (cargo1 === cargoUsuario || (cargo2 === cargoUsuario && cargo2 !== undefined)) {
            return true
        } else {
            return false
        }
    }

    const estaAutorizado = async (cargo1, cargo2) => {
        const cargoUsuario = userInfo.cargo;
        if (cargo1 === cargoUsuario || (cargo2 === cargoUsuario && cargo2 !== undefined)) {
            return true
        } else {
            navigate('/login');
            return false
        }

    }

    const pegaGrupoAutorizado = async () => {

        const cargo = userInfo.cargo;
        if (cargo === 'SUPER_USER' || cargo === 'ADMIN' || cargo === 'CESS_USER') {
            return await apiGrupo.fetchApi().then(res => {
                return res;
            }).catch(error => {
                console.error(error)
            })
        }

        if (cargo === 'BASIC_USER' || cargo === 'ADMIN_UNIDADE') {
            const unId = parseInt(userInfo.unidade);
            if (unId !== undefined) {
                setUnidadeIdCookie(unId);
                window.dispatchEvent(new Event('storage'));
                navigate('/monitoramento/geral');
            } else {
                aviso("Este usuário não tem unidade vinculada!");
            }

        }

        if (cargo === 'DIRETOR') {
            if (userInfo.grupo !== "") {
                return {
                    data: ['DIRETOR_GRUPO', userInfo.grupo]
                }
            } else {
                return {
                    data: ['DIRETOR_EMPRESA', userInfo.empresa]
                }
            }

        }

        return [];
    }



    return (
        <AuthContext.Provider value={{
            verificarCargo,
            estaAutorizado,
            pegaGrupoAutorizado,
            deslogar,
            userInfo,
        }}>
            {children}
        </AuthContext.Provider>

    )
}