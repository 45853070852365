import { format } from "date-fns";
import useAsyncFunction from "../../hooks/useAsyncFunction"
import BasicTable from "../common/BasicTable";
import Table from "../common/table";
import apiCompressores from "../../services/apis/apiCompressores";

export default function CompressorTable({ equipamento }) {
        
    const { data, loading, error } = useAsyncFunction(async () => await apiCompressores.getEquipamentoComUltimasLeituras(equipamento.id, 50)) || [];

    if (loading) return <p>Carregando...</p>;

    if(!data || error) {
        return null
    }
        

    const leituras = data?.leiturasCompressores || [];

    
    return <BasicTable 
        headerContent={["LEITURA ID", "LIGADO", "DATA HORA", "P. SUCÇÃO", "P. DESCARGA", "P. ÓLEO RESERVATÓRIO", "P. ÓLEO APÓS FILTRO", "P. SETUP", "T. SUCÇÃO", "T. DESCARGA", " T. ÓLEO", "CAPACIDADE", "CORRENTE", "STATUS", "FREQUÊNCIA", "SLIDE", "POTÊNCIA KW"]} 
    >   
        {
            leituras.map((leitura) => (
            <Table.Row key={leitura.id}>
                <Table.Data>{leitura.id}</Table.Data>
                <Table.Data>{leitura.ligado ? "on" : "off"}</Table.Data>
                <Table.Data>{format(leitura.dataHora, "dd/MM/yyyy HH:mm:ss")}</Table.Data>
                <Table.Data>{leitura.pressaoSuccao}</Table.Data>
                <Table.Data>{leitura.pressaoDescarga}</Table.Data>
                <Table.Data>{leitura.pressaoOleoReservatorio}</Table.Data>
                <Table.Data>{leitura.pressaoOleoAposFiltro}</Table.Data>
                <Table.Data>{leitura.pressaoSetup}</Table.Data>
                <Table.Data>{leitura.temperaturaSuccao}</Table.Data>
                <Table.Data>{leitura.temperaturaDescarga}</Table.Data>
                <Table.Data>{leitura.temperaturaOleo}</Table.Data>
                <Table.Data>{leitura.capacidadeAtual}</Table.Data>
                <Table.Data>{leitura.corrente}</Table.Data>
                <Table.Data>{leitura.status}</Table.Data>
                <Table.Data>{leitura.frequencia}</Table.Data>
                <Table.Data>{leitura.slide}</Table.Data>
                <Table.Data>{leitura.potenciaKw}</Table.Data>
            </Table.Row>
            ))
        }
    </BasicTable>

}