import { apiLink } from '../../constants/env';
import axiosInstance from '../AxiosInstance';

const apiChiller = {
    fetchApi: async (page) => {
        const URL = `${apiLink}api/chiller?page=` + page;
        return await axiosInstance.get(URL)
            .then((res) => res)
            .catch(error => {
                console.error('Erro ao buscar os dados:', error);
                throw new Error();
            })
    },
    postApi: async (chiller) => {
        const URL = `${apiLink}api/chiller`;
        return await axiosInstance.post(URL, chiller)
            .then((res) => res)
            .catch(error => {
                console.error('Erro ao buscar os dados:', error);
                throw new Error();
            })
    },
    deleteApi: async (id) => {
        const URL = `${apiLink}api/chiller/status/${id}`;
        return await axiosInstance.get(URL)
            .then((res) => res)
            .catch(error => {
                console.error('Erro ao buscar os dados:', error);
                throw new Error();

            })
    },
    putApi: async (id, chiller) => {
        const URL = `${apiLink}api/chiller/${id}`;
        return await axiosInstance.put(URL, chiller)
            .then((res) => res)
            .catch(error => {
                console.error('Erro ao buscar os dados:', error);
                throw new Error();

            })
    },
    getByRegimeId: async (id) => {
        const URL = `${apiLink}api/chiller/regime/${id}`;
        return await axiosInstance.get(URL)
            .then((res) => res)
            .catch(error => {
                console.error('Erro Fetch Chiller [AXIOS]:', error);
                throw new Error();
            })
    },
    getByUnidadeId: async (id) => {
        const URL = `${apiLink}api/chiller/unidade/${id}`;
        return await axiosInstance.get(URL)
            .then((res) => res)
            .catch(error => {
                console.error('Erro ao pegar dados do Chiller:', error);
                throw new Error();
            })
    },
    getEquipamentoComUltimasLeituras: async (equipamentoId, numLeituras) => {
        const URL = `${apiLink}api/chiller/${equipamentoId}/numero-leituras/${numLeituras}`;
        return await axiosInstance.get(URL)
            .then((res) => res)
            .catch(error => {
                console.error('Erro ao pegar regime:', error);
                throw new Error('Erro ao pegar regime:', error);
            })
    }
}

export default apiChiller; 