import { apiLink } from '../../../constants/env';
import axiosInstance from '../../AxiosInstance';

const apiLeituraLinhaLiquido = {
    getUltimaLeitura: async (id) => {
        const URL = `${apiLink}api/leitura-linha-liquido/ultima-leitura?unidadeId=${id}`;
        return await axiosInstance.get(URL)
            .then((res) => res)
            .catch(error => {
                throw new Error(error);
            })
    },
    getEquipamentoComUltimasLeituras: async (equipamentoId, numLeituras) => {
        const URL = `${apiLink}api/leitura-linha-liquido/${equipamentoId}/numero-leituras/${numLeituras}`;
        return await axiosInstance.get(URL)
            .then((res) => res)
            .catch(error => {
                console.error('Erro ao pegar linha líquido:', error);
                throw new Error('Erro ao pegar linha líquido:', error);
            })
    }
}

export default apiLeituraLinhaLiquido;
