import Table from "./table";

export default function BasicTable({ headerContent, children }) {
    return <Table.Root>
        <Table.Head>
            {
                headerContent.map((header) => (
                    <Table.Header key={header}>{header}</Table.Header>
                ))
            }
        </Table.Head>
        <Table.Body>
                {children}
        </Table.Body>

    </Table.Root>
}