import { useEffect, useState } from "react";
import Table from "../common/table";
import getCurrentUnidadeId from "../../funcs/getCurrentUnidadeId";
import { format } from "date-fns";
import apiVentilador from "../../services/apis/apiVentilador";
import { orderBy } from "../../funcs";

export default function Ventiladores() {

    const [leituras, setLeituras] = useState([]);
    const unidadeId = getCurrentUnidadeId();

    useEffect(() => {
        (async () => {
            const res = await apiVentilador.getByUnidadeIdV2(unidadeId);
            const ventiladores = res.data;
            const leiturasVentiladores = ventiladores.map(({leiturasVentiladores}) => (leiturasVentiladores[0]));                
            orderBy(leiturasVentiladores, "ventiladorId");
            setLeituras(leiturasVentiladores);
        })();
    },[unidadeId]);



    return 	<div className="w-full">
        <Table.Root>
            <Table.Head>
                <Table.Header>VT id</Table.Header>
                <Table.Header>Ligado</Table.Header>
                <Table.Header>Data hora</Table.Header>
                <Table.Header>Corrente</Table.Header>
                <Table.Header>Frequência</Table.Header>
                <Table.Header>Potência Calculada</Table.Header>
            </Table.Head>
            <Table.Body>
                {leituras.map(
                    (leitura) => (
                        <Table.Row
                            key={leitura.id}
                        >
                            <Table.Data>{leitura.ventiladorId}</Table.Data>
                            <Table.Data>{leitura.ligado ? "on" : "off"}</Table.Data>
                            <Table.Data>{format(leitura.dataHora, "dd/MM/yyyy HH:mm:ss")}</Table.Data>
                            <Table.Data>{leitura.corrente}</Table.Data>
                            <Table.Data>{leitura.frequencia}</Table.Data>
                            <Table.Data>{leitura.potenciaCalculada}</Table.Data>
                        </Table.Row>
                    )
                )}
            </Table.Body>
        </Table.Root>
    </div>
}