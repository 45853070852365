import { useEffect, useState } from "react";
import Table from "../common/table";
import getCurrentUnidadeId from "../../funcs/getCurrentUnidadeId";
import apiCompressores from "../../services/apis/apiCompressores";
import { format } from "date-fns";
import { orderBy } from "../../funcs";

export default function Compressores() {

    const [leituras, setLeituras] = useState([]);
    const unidadeId = getCurrentUnidadeId();

    useEffect(() => {
        (async () => {
            const res = await apiCompressores.getByUnidadeId(unidadeId);
            const compressores = res.data;
            const leiturasCompressores = compressores.map(({leiturasCompressores}) => (leiturasCompressores[0]));                
            orderBy(leiturasCompressores, "compressorId");

            setLeituras(leiturasCompressores);
        })();
    },[unidadeId]);

    return 	<div className="w-full">
        <Table.Root>
            <Table.Head>
                <Table.Header>CP id</Table.Header>
                <Table.Header>Ligado</Table.Header>
                {/* <Table.Header>Status</Table.Header> */}
                <Table.Header>Data hora</Table.Header>
                <Table.Header>P. succão</Table.Header>
                <Table.Header>P. descarga</Table.Header>
                <Table.Header>P. óleo reservatório</Table.Header>
                <Table.Header>P. óleo após filtro</Table.Header>
                <Table.Header>P. setup</Table.Header>
                <Table.Header>T. sucção</Table.Header>
                <Table.Header>T. descarga</Table.Header>
                <Table.Header>T. óleo</Table.Header>
                <Table.Header>Capacidade atual</Table.Header>
                <Table.Header>Corrente</Table.Header>
                {/* <Table.Header>Horas</Table.Header> */}
                <Table.Header>Status</Table.Header>
                <Table.Header>Frequência</Table.Header>
                <Table.Header>Slide</Table.Header>
                <Table.Header>Potência kw</Table.Header>
            </Table.Head>
            <Table.Body>
                {leituras.map(
                    (leitura) => (
                        <Table.Row
                            key={leitura.id}
                        >
                            <Table.Data>{leitura.compressorId}</Table.Data>
                            <Table.Data>{leitura.ligado ? "on" : "off"}</Table.Data>
                            <Table.Data>{format(leitura.dataHora, "dd/MM/yyyy HH:mm:ss")}</Table.Data>
                            <Table.Data>{leitura.pressaoSuccao}</Table.Data>
                            <Table.Data>{leitura.pressaoDescarga}</Table.Data>
                            <Table.Data>{leitura.pressaoOleoReservatorio}</Table.Data>
                            <Table.Data>{leitura.pressaoOleoAposFiltro}</Table.Data>
                            <Table.Data>{leitura.pressaoSetup}</Table.Data>
                            <Table.Data>{leitura.temperaturaSuccao}</Table.Data>
                            <Table.Data>{leitura.temperaturaDescarga}</Table.Data>
                            <Table.Data>{leitura.temperaturaOleo}</Table.Data>
                            <Table.Data>{leitura.capacidadeAtual}</Table.Data>
                            <Table.Data>{leitura.corrente}</Table.Data>
                            <Table.Data>{leitura.status}</Table.Data>
                            <Table.Data>{leitura.frequencia}</Table.Data>
                            <Table.Data>{leitura.slide}</Table.Data>
                            <Table.Data>{leitura.potenciaKw}</Table.Data>
                        </Table.Row>
                    )
                )}
            </Table.Body>
        </Table.Root>
    </div>
}