import { useEffect, useState } from "react";
import Table from "../common/table";
import getCurrentUnidadeId from "../../funcs/getCurrentUnidadeId";
import { format } from "date-fns";
import apiTbu from "../../services/apis/apiTbu";

export default function TBU() {

    const [leitura, setLeitura] = useState();
    const unidadeId = getCurrentUnidadeId();

    useEffect(() => {
        (async () => {
        const res = await apiTbu.ultimaLeitura(unidadeId);
            const tbu = res.data;
            setLeitura(tbu);
        })();
    },[unidadeId]);

    return 	<div className="w-full">
        <Table.Root>
            <Table.Head>

                <Table.Header>Ligado</Table.Header>
                <Table.Header>Data hora</Table.Header>
                <Table.Header>Temperatura</Table.Header>
                <Table.Header>Umidade</Table.Header>
                <Table.Header>TBU</Table.Header>
            </Table.Head>
            <Table.Body>
                {
                    leitura &&
                    <Table.Row
                        key={leitura.id}
                    >
                        <Table.Data>{leitura.ligado ? "on" : "off"}</Table.Data>
                        <Table.Data>{format(leitura.dataHora, "dd/MM/yyyy HH:mm:ss")}</Table.Data>
                        <Table.Data>{leitura.temperatura}</Table.Data>
                        <Table.Data>{leitura.umidade}</Table.Data>
                        <Table.Data>{leitura.tbuCalculado}</Table.Data>
                    </Table.Row>
                }
            </Table.Body>
        </Table.Root>
    </div>
}