import React from 'react';
import { cn } from '../../../lib/utils/utils';

export const TableData = ({ children, className, span, props }) => {
	return (
		<td
			className={cn(
				'py-[2px] px-1 text-center text-[15px] 3xl:text-lg whitespace-nowrap',
				className,
			)}
			colSpan={span}
			{...props}
		>
			{children}
		</td>
	);
};
