import { useEffect, useState } from "react";
import Table from "../common/table";
import getCurrentUnidadeId from "../../funcs/getCurrentUnidadeId";
import { format } from "date-fns";
import apiCondensadores from "../../services/apis/apiCondensadores";
import { orderBy } from "../../funcs";

export default function Condensadores() {

    const [leituras, setLeituras] = useState([]);
    const unidadeId = getCurrentUnidadeId();

    useEffect(() => {
        (async () => {
            const res = await apiCondensadores.getByUnidadeId(unidadeId);
            const condensadores = res.data;
            const leituraCondensadores = condensadores.map(({leiturasCondensadores}) => (leiturasCondensadores[0]));                
            orderBy(leituraCondensadores, "condensadorId");
            setLeituras(leituraCondensadores);
        })();
    },[unidadeId]);

    return 	<div className="w-full">
        <Table.Root>
            <Table.Head>
                <Table.Header>CON id</Table.Header>
                <Table.Header>Ligado</Table.Header>
                {/* <Table.Header>Status</Table.Header> */}
                <Table.Header>Data hora</Table.Header>
                <Table.Header>T. entrada</Table.Header>
                <Table.Header>T. saída</Table.Header>
                <Table.Header>Umidade relativa entrada</Table.Header>
                <Table.Header>Umidade relativa saída</Table.Header>
                <Table.Header>Velocidade ar entrada</Table.Header>
            </Table.Head>
            <Table.Body>
                {leituras.map(
                    (leitura) => (
                        <Table.Row
                            key={leitura.id}
                        >
                            <Table.Data>{leitura.condensadorId}</Table.Data>
                            <Table.Data>{leitura.ligado ? "on" : "off"}</Table.Data>
                            <Table.Data>{format(leitura.dataHora, "dd/MM/yyyy HH:mm:ss")}</Table.Data>
                            <Table.Data>{leitura.temperaturaEntrada}</Table.Data>
                            <Table.Data>{leitura.temperaturaSaida}</Table.Data>
                            <Table.Data>{leitura.umidadeRelativaEntrada}</Table.Data>
                            <Table.Data>{leitura.umidadeRelativaSaida}</Table.Data>
                            <Table.Data>{leitura.velocidadeArEntrada}</Table.Data>
                        </Table.Row>
                    )
                )}
            </Table.Body>
        </Table.Root>
    </div>
}