import axios from 'axios';
import { apiLink } from '../../constants/env';
import { getAccessToken } from '../../funcs';
import axiosInstance from '../AxiosInstance';

const apiAtuth = {
    login: async (usuario) => {
        const URL = `${apiLink}api/auth/login`;
        return await axiosInstance.post(URL, usuario, {
            headers: {
                'Authorization': `bearer ${getAccessToken()}`
            }
        })
            .then((res) => res)
            .catch(error => {
                console.error('Erro ao buscar os dados:', error);
            })
    },
    register: async (usuario) => {
        const URL = `${apiLink}api/auth/register`;
        return await axiosInstance.post(URL, usuario, {
            headers: {
                'Authorization': `bearer ${getAccessToken()}`
            }
        })
    },
    userInfo: async () => {
        const URL = `${apiLink}api/auth/user-info`;
        return await axiosInstance.get(URL, {
            headers: {
                'Authorization': `bearer ${getAccessToken()}`
            }
        })
    },
    refreshToken: async (tokens) => {
        const URL = `${apiLink}api/auth/refresh-token`;
        return await axiosInstance.post(URL, tokens, {
            headers: {
                'Authorization': `bearer ${getAccessToken()}`
            }
        })
            .then((res) => res)
            .catch(error => error)
    },
    resetPasswordRequest: async (userName) => {
        const URL = `${apiLink}api/auth/reset-password-request`;
        return await axiosInstance.post(URL, userName)
            .then((res) => res)
            .catch(error => {
                console.error('Erro ao buscar os dados:', error);
                throw new Error();
            })
    },
    resetPassword: async (form) => {
        const URL = `${apiLink}api/auth/reset-password`;
        return await axiosInstance.post(URL, form)
            .then((res) => res)
            .catch(error => {
                console.error('Erro ao buscar os dados:', error);
                throw new Error(error.message);
            })
    },
    getUnidadeToken: async (id) => {
        const URL = `${apiLink}api/auth/unidade/${id}`;
        return await axiosInstance.get(URL)
            .then((res) => res)
            .catch(error => {
                console.error('Erro ao buscar os dados:', error);
                throw new Error(error.message);
            })
    },
    refreshTokenUnidade: async (id) => {
        const URL = `${apiLink}api/auth/refresh-token/unidade/${id}`;
        return await axios.get(URL, {
            withCredentials: true
        })
            .then((res) => res)
            .catch(error => {
                console.error('Erro ao buscar os dados:', error);
                throw new Error(error.message);
            })
    },
}

export default apiAtuth;

