import React from 'react';
import { cn } from '../../lib/utils/utils';

const TitleCardSimple = ({ children, className }) => {
	return (
		<>
			<h1 className={cn("py-2 lg:text-2xl md:text-lg text-sm font-semibold text-secondary drop-shadow-1xl", className)}>
				{children}
			</h1>
		</>
	);
};

export default TitleCardSimple;
