import { useContext } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import NotFound from "../NotFound";
import ListaEquipamentos from "../../components/leituras/ListaEquipamentos";
import getCurrentUnidadeId from "../../funcs/getCurrentUnidadeId";
import apiRegimes from "../../services/apis/apiRegimes";
import RegimeTable from "../../components/leituras/RegimeTable";
import apiCompressores from "../../services/apis/apiCompressores";
import CompressorTable from "../../components/leituras/CompressorTable";
import apiCondensadores from "../../services/apis/apiCondensadores";
import CondensadorTable from "../../components/leituras/CondensadorTable";
import apiBombas from "../../services/apis/apiBomba";
import BombaTable from "../../components/leituras/BombaTable";
import VentiladorTable from "../../components/leituras/VentiladorTable";
import apiVentilador from "../../services/apis/apiVentilador";
import apiAmbientes from "../../services/apis/apiAmbientes";
import AmbienteTable from "../../components/leituras/AmbienteTable";
import apiChiller from "../../services/apis/apiChiller";
import ChillerTable from "../../components/leituras/ChillerTable";
import LeiturasUnicas from "../../components/leituras/LeiturasUnicas";

export default function Leituras() {
    const { userInfo } = useContext(AuthContext);
    const unidadeId = getCurrentUnidadeId();
    if(!userInfo?.cargo) {
        return null;
    }
    
    return (
        ["ADMIN", "SUPER_USER"].includes(userInfo.cargo) ?  
        <div className="w-full flex flex-col gap-5 pb-5">

            <ListaEquipamentos 
                titulo={"Regimes"} 
                getEquipamento={() => apiRegimes.getByUnidadeId(unidadeId)}
                equipamentoTable={RegimeTable}
            />
            <ListaEquipamentos
                titulo={"Compressores"}
                getEquipamento={() => apiCompressores.getByUnidadeId(unidadeId)}
                equipamentoTable={CompressorTable}
            />
            <ListaEquipamentos
                titulo={"Condensadores"}
                getEquipamento={() => apiCondensadores.getByUnidadeId(unidadeId)}
                equipamentoTable={CondensadorTable}
            />
            <ListaEquipamentos
                titulo={"Bombas"}
                getEquipamento={() => apiBombas.getByUnidadeIdV2(unidadeId)}
                equipamentoTable={BombaTable}
            />
            <ListaEquipamentos
                titulo={"Ventiladores"}
                getEquipamento={() => apiVentilador.getByUnidadeIdV2(unidadeId)}
                equipamentoTable={VentiladorTable}
            />
            <ListaEquipamentos
                titulo={"Ambientes"}
                getEquipamento={() => apiAmbientes.getByUnidadeId(unidadeId)}
                equipamentoTable={AmbienteTable}
            />
            <ListaEquipamentos
                titulo={"Chillers"}
                getEquipamento={() => apiChiller.getByUnidadeId(unidadeId)}
                equipamentoTable={ChillerTable}
            />

            <LeiturasUnicas />
        </div> : 
        <NotFound/>
    )
}