import CardLayout from "../common/CardLayout";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "../ui/accordion";
import { useState } from "react";
import LinhaLiquidoTable from "./LinhaLiquidoTable";
import TbuTable from "./TbuTable";

export default function LeiturasUnicas() {

    const [value, setValue] = useState("item-0");
    const [nastedValue, setNastedValue] = useState("item-equipamento-0");

    return <div className="flex flex-col gap-4 py-1">
        {
            <CardLayout>
                <div className="font-semibold p-5">
                    <Accordion
                        type="single"
                        collapsible={true}
                        value={value}
                        onValueChange={(newValue) => setValue(newValue)}
                    >
                        <AccordionItem
                            value={"item-leitura-unica"}>
                            <AccordionTrigger>
                                <div className="w-full flex items-center justify-center text-sm lg:text-2xl drop-shadow-1xl mb-3">
                                    <div
                                        className={`uppercase`}
                                    >
                                        <p>
                                            <span className="capitalize mr-5">
                                                {
                                                    "Leituras Únicas"
                                                }
                                            </span>
                                        </p>
                                    </div>
                                </div>
                            </AccordionTrigger>
                            <AccordionContent>
                                <Accordion
                                    type="single"
                                    collapsible={true}
                                    value={nastedValue}
                                    onValueChange={(newValue) => setNastedValue(newValue)}
                                >
                                    <AccordionItem
                                        value={`linha-liquido`}
                                    >
                                        <div className="font-semibold drop-shadow-1xl bg-slate-100 border-[1px] border-gray-700 rounded-md text-slate-500 dark:bg-slate-800 dark:text-slate-400 shadow-lg px-2">
                                            <AccordionTrigger>
                                                <div className="w-full flex items-center justify-around text-sm lg:text-2xl ">
                                                    <div
                                                        className={`uppercase`}
                                                    >
                                                        <p>
                                                            <span className="capitalize">
                                                                Linha Líquido
                                                            </span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </AccordionTrigger>
                                        </div>
                                        <AccordionContent>
                                            <LinhaLiquidoTable />
                                        </AccordionContent>
                                    </AccordionItem>
                                </Accordion>
                                <Accordion
                                    type="single"
                                    collapsible={true}
                                    value={nastedValue}
                                    onValueChange={(newValue) => setNastedValue(newValue)}
                                >
                                    <AccordionItem
                                        value={`tbu`}
                                    >
                                        <div className="font-semibold drop-shadow-1xl bg-slate-100 border-[1px] border-gray-700 rounded-md text-slate-500 dark:bg-slate-800 dark:text-slate-400 shadow-lg px-2">
                                            <AccordionTrigger>
                                                <div className="w-full flex items-center justify-around text-sm lg:text-2xl ">
                                                    <div
                                                        className={`uppercase`}
                                                    >
                                                        <p>
                                                            <span className="capitalize">
                                                                TBU
                                                            </span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </AccordionTrigger>
                                        </div>
                                        <AccordionContent>
                                            <TbuTable />
                                        </AccordionContent>
                                    </AccordionItem>
                                </Accordion>
                            </AccordionContent>
                        </AccordionItem>

                    </Accordion>
                </div>

            </CardLayout>
        }
    </div>
}