import CardLayout from "../common/CardLayout";
import NoDataAlert from "../common/returns/NoDataAlert";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "../ui/accordion";
import useAsyncFunction from "../../hooks/useAsyncFunction";
import { createElement, useState } from "react";

export default function ListaEquipamentos({ titulo, getEquipamento, equipamentoTable }) {

    const { data, loading, error } = useAsyncFunction(getEquipamento) || [];
    const [value, setValue] = useState("item-0");
    const [nastedValue, setNastedValue] = useState("item-equipamento-0");

    if (loading) return <p>Carregando...</p>;
    if (error) return <p>Erro ao carregar dados: {error.message}</p>;

    return <div className="flex flex-col gap-4 py-1">
        {
            <CardLayout>
                <div className="font-semibold p-5">
                    <Accordion
                        type="single"
                        collapsible={true}
                        value={value}
                        onValueChange={(newValue) => setValue(newValue)}
                    >
                        <AccordionItem 
                        value={"item-" + titulo}>
                            <AccordionTrigger>
                                <div className="w-full flex items-center justify-center text-sm lg:text-2xl drop-shadow-1xl mb-3">
                                    <div
                                        className={`uppercase`}
                                    >
                                        <p>
                                            <span className="capitalize mr-5">
                                                {
                                                    titulo
                                                }
                                            </span>
                                        </p>
                                    </div>
                                </div>
                            </AccordionTrigger>
                            <AccordionContent>
                                
                                {
                                    data.length === 0 ? <NoDataAlert /> : data.map((equipamento) => (
                                        <Accordion
                                            type="single"
                                            collapsible={true}
                                            value={nastedValue}
                                            onValueChange={(newValue) => setNastedValue(newValue)}
                                            key={equipamento.id}
                                        >
                                            <AccordionItem
                                                value={`item-${equipamento.id}`}
                                            >
                                                <div className="font-semibold drop-shadow-1xl bg-slate-100 border-[1px] border-gray-700 rounded-md text-slate-500 dark:bg-slate-800 dark:text-slate-400 shadow-lg px-2">
                                                    <AccordionTrigger>
                                                        <div className="w-full flex items-center justify-around text-sm lg:text-2xl ">
                                                            {equipamento &&
                                                                <div
                                                                    className={`uppercase`}
                                                                >
                                                                    <p>
                                                                        <span className="capitalize">
                                                                            {
                                                                                equipamento.nome + " | ID: " + equipamento.id
                                                                            }
                                                                        </span>
                                                                    </p>
                                                                </div>
                                                            }
                                                        </div>
                                                    </AccordionTrigger>
                                                </div>
                                                    <AccordionContent>
                                                        {
                                                            createElement(equipamentoTable, { equipamento })
                                                        }
                                                    </AccordionContent>
                                            </AccordionItem>
                                        </Accordion>
                                    ))
                                }
                            </AccordionContent>
                        </AccordionItem>

                    </Accordion>
                </div>

            </CardLayout>
        }
    </div>
}