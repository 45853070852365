import { getUnidadeIdCookie } from ".";

export default function getCurrentUnidadeId() {
    const unidadeId = getUnidadeIdCookie();

    if (unidadeId === undefined) {
        window.location.href = '/escolha-unidade';
        return null;
    }

    return unidadeId;
}
