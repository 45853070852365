import CardLayout from "../common/CardLayout";
import TitleCard from "../common/TitleCard";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "../ui/accordion";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../ui/tabs";
import { useEffect, useState } from "react";
import Compressores from "./Compressores";
import Condensadores from "./Condensadores";
import Bombas from "./Bombas";
import Ventiladores from "./Ventiladores";
import Regimes from "./Regimes";
import TBU from "./TBU";
import LinhaLiquido from "./LinhaLiquido";

export default function Equipamentos() {

    const TabItems = {
        item0: [
            'Regimes',
            <Regimes key={"regimes"} />
        ],
		item1: [
			'Compressores',
			<Compressores key={"compressores"} />
		],
		item2: [
			'Condensadores',
			<Condensadores key={"condensadores"}/>,
		],
		item3: [
			'Bombas',
			<Bombas key={"bombas"}/>,
		],
		item4: [
			'Ventiladores',
			<Ventiladores key={"ventiladores"}/>,
		],
        item5: [
            'TBU',
            <TBU key={"tbu"} />
        ],
        item6: [
            'Linha Líquido',
            <LinhaLiquido key={"linha-liquido"} />
        ]
	};

	const [activeTab, setActiveTab] = useState(
		Object.keys(TabItems)[0].toLowerCase()
	);
	useEffect(() => {}, [activeTab]);

    return (
        <CardLayout>
        <Accordion
            type="single"
            defaultValue="item-1"
            collapsible="true"
        >
            <AccordionItem value="item-1">
                <div className="font-semibold">
                    <AccordionTrigger>
                        <TitleCard>
                            Leituras
                        </TitleCard>
                    </AccordionTrigger>
                </div>
                <AccordionContent>
                    <div className="flex flex-col w-full min-h-[10rem]">
                        <Tabs
                            defaultValue={Object.keys(
                                TabItems
                            )[0].toLowerCase()}
                            className="flex mx-auto w-full flex-col"
                        >
                            <TabsList>
                                <div className="flex flex-col lg:flex-row h-full w-full items-center justify-center">
                                    {Object.keys(
                                        TabItems
                                    ).map(
                                        (
                                            key,
                                            index
                                        ) => (
                                            <TabsTrigger
                                                className="lg:px-2 2xl:mx-6 5xl:mx-16 5xl:text-2xl rounded-md"
                                                key={
                                                    index
                                                }
                                                value={key.toLowerCase()}
                                                onClick={() =>
                                                    setActiveTab(
                                                        key.toLowerCase()
                                                    )
                                                }
                                            >
                                                {
                                                    TabItems[
                                                        key
                                                    ][0]
                                                }{' '}
                                            </TabsTrigger>
                                        )
                                    )}
                                </div>
                            </TabsList>
                            {Object.keys(TabItems).map(
                                (key, index) => (
                                    <TabsContent
                                        className={`${
                                            activeTab ===
                                            key
                                                ? 'opacity-1 transform translate-y-[0px] transition-all duration-500'
                                                : 'opacity-0 transform translate-y-[-20px] transition-all duration-500'
                                        }`}
                                        key={index}
                                        value={key.toLowerCase()}
                                    >
                                        <div className="flex flex-col w-full justify-center items-center">
                                            {
                                                TabItems[
                                                    key
                                                ][1]
                                            }
                                        </div>
                                    </TabsContent>
                                )
                            )}
                        </Tabs>
                    </div>
                </AccordionContent>
            </AccordionItem>
        </Accordion>
    </CardLayout>
    )
}