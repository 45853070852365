import { format } from "date-fns";
import useAsyncFunction from "../../hooks/useAsyncFunction"
import BasicTable from "../common/BasicTable";
import Table from "../common/table";
import getCurrentUnidadeId from "../../funcs/getCurrentUnidadeId";
import apiTbu from "../../services/apis/apiTbu";

export default function TbuTable() {
        
    const unidadeId = getCurrentUnidadeId();
    const { data, loading, error } = useAsyncFunction(async () => await apiTbu.getEquipamentoComUltimasLeituras(unidadeId, 50)) || [];

    if (loading) return <p>Carregando...</p>;

    if(!data || error) {
        return null
    }
            
    return <BasicTable 
        headerContent={["LEITURA ID", "DATA HORA", "TEMPERATURA", "UMIDADE", "TBU CALCULADO"]} 
    >   
        {
            data?.map((leitura) => (
            <Table.Row key={leitura.id}>
            <Table.Data>
                    {
                        leitura.id
                    }
                </Table.Data>
                <Table.Data>
                    {
                        format(leitura.dataHora, "dd/MM/yyyy HH:mm:ss")
                    }
                </Table.Data>
                <Table.Data>
                    {
                        leitura.temperatura
                    }
                </Table.Data>
                <Table.Data>
                    {
                        leitura.umidade
                    }
                </Table.Data>
                <Table.Data>
                    {
                        leitura.tbuCalculado
                    }
                </Table.Data>
            </Table.Row>
            ))
        }
    </BasicTable>

}