import Cookies from 'js-cookie'
import { accessTokenKeyName, refreshTokenKeyName, unidadeIdKeyName, unitAccessTokenKeyName, unitRefreshToken } from '../constants';

export const getPctg = (value, pctg) => {
    if(pctg === 0) return 0;
    
    return (value / 100) * pctg;
}

export function getClientTimeZoneOffset() {
    const offsetInMinutes = new Date().getTimezoneOffset();
    const offsetInHours = -offsetInMinutes / 60;
    return offsetInHours;
}

export function orderBy(array, parm) {
    array.sort((a, b) => {
        if (a[parm] < b[parm]) {
            return -1;
        }
        if (a[parm] > b[parm]) {
            return 1;
        }
        return 0;
    });

    return array;
}

export function setAccessToken(value) {
    Cookies.set(accessTokenKeyName, value);
}

export function setRefreshToken(value) {
    Cookies.set(refreshTokenKeyName, value);
}

export function setUnidadeIdCookie(value) {
    Cookies.set(unidadeIdKeyName, value)
}

export function getAccessToken() {
    return Cookies.get(accessTokenKeyName);
}

export function getRefreshToken() {
    return Cookies.get(refreshTokenKeyName);
}

export function getUnidadeIdCookie() {
    return Cookies.get(unidadeIdKeyName);
}

export function clearAuthCookies() {
    Cookies.remove(accessTokenKeyName);
    Cookies.remove(refreshTokenKeyName);
    Cookies.remove(unidadeIdKeyName);
    Cookies.remove(unitAccessTokenKeyName);
    Cookies.remove(unitRefreshToken);
}




