import { format } from "date-fns";
import useAsyncFunction from "../../hooks/useAsyncFunction"
import apiRegimes from "../../services/apis/apiRegimes";
import BasicTable from "../common/BasicTable";
import Table from "../common/table";

export default function RegimeTable({ equipamento }) {
        
    const { data, loading, error } = useAsyncFunction(async () => await apiRegimes.getEquipamentoComUltimasLeituras(equipamento.id, 50)) || [];

    if (loading) return <p>Carregando...</p>;

    if(!data || error) {
        return null
    }
        

    const leiturasRegimes = data?.leiturasRegimes || [];

    
    return <BasicTable 
        headerContent={["LEITURA ID", "TIPO REGIME", "DATA HORA", "PRESSÃO ATUAL"]} 
    >   
        {
            leiturasRegimes.map((leitura) => (
            <Table.Row key={leitura.id}>
                <Table.Data>{leitura.id}</Table.Data>
                <Table.Data>{equipamento.tipoRegime}</Table.Data>
                <Table.Data>{format(leitura.dataHora, "dd/MM/yyyy HH:mm:ss")}</Table.Data>
                <Table.Data>{leitura.pressaoAtual}</Table.Data>
            </Table.Row>
            ))
        }
    </BasicTable>

}