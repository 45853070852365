import CardLayout from "../common/CardLayout";
import TitleCard from "../common/TitleCard";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "../ui/accordion";
import Table from "../common/table";
import { useEffect, useState } from "react";
import getCurrentUnidadeId from "../../funcs/getCurrentUnidadeId";
import { format } from "date-fns";
import { GiWaterDrop } from "react-icons/gi";
import apiChiller from "../../services/apis/apiChiller";
import { orderBy } from "../../funcs";

export default function Chillers() {

    const unidadeId = getCurrentUnidadeId();
    const [leituras, setLeituras] = useState([]);

    useEffect(() => {
        (async () => {
            const res = await apiChiller.getByUnidadeId(unidadeId);
            const chillers = res.data;
            const leiturasChillers = chillers.map(({leiturasChillers}) => (leiturasChillers[0]));                
            orderBy(leiturasChillers, "chillerId");
            setLeituras(leiturasChillers);
        })();
    },[unidadeId]);


    return <CardLayout>
    <Accordion
        type="single"
        defaultValue="item-1"
        collapsible="true"
    >
        <AccordionItem value="item-1">
            <div className="font-semibold">
                <AccordionTrigger>
                    <TitleCard>
                        Chillers
                        <p className="text-lg lg:text-4xl px-2">
                            <GiWaterDrop />
                        </p>
                    </TitleCard>
                </AccordionTrigger>
            </div>
            <AccordionContent>
                <div className="w-full">
                        <Table.Root>
                            <Table.Head>
                                {/* <Table.Header>
                                    <p className="font-bold">
                                        . . .
                                    </p>
                                </Table.Header> */}
                                <Table.Header>
                                    Chiller id
                                </Table.Header>
                                <Table.Header>
                                    Ligado
                                </Table.Header>
                                <Table.Header>
                                    Data Hora
                                </Table.Header>
                                <Table.Header>
                                    Temperatura Entrada
                                </Table.Header>
                                <Table.Header>
                                    Temperatura Saída
                                </Table.Header>
                            </Table.Head>
                            <Table.Body>
                                {leituras?.map(
                                    (
                                        leitura
                                    ) => (
                                        <Table.Row
                                            key={
                                                leitura.id
                                            }
                                        >
                                            <Table.Data>
                                                {
                                                    leitura.chillerId
                                                }
                                            </Table.Data>
                                            <Table.Data>
                                                {
                                                    leitura.ligado ? "on" : "off"
                                                }
                                            </Table.Data>
                                            <Table.Data>
                                                {
                                                    format(leitura.dataHora, "dd/MM/yyyy HH:mm:ss") === "01/01/0001 00:00:00" ? "Sem leitura" : format(leitura.dataHora, "dd/MM/yyyy HH:mm:ss")
                                                }
                                            </Table.Data>
                                            <Table.Data>
                                                {
                                                    leitura.temperaturaEntrada
                                                }
                                            </Table.Data>
                                            <Table.Data>
                                                {
                                                    leitura.temperaturaSaida
                                                }
                                            </Table.Data>
                                        </Table.Row>
                                    )
                                )}
                            </Table.Body>
                        </Table.Root>
                </div>
            </AccordionContent>
        </AccordionItem>
    </Accordion>
</CardLayout>
}